import { Container } from "./styles";
import sof from '../../assets/projects/sof.png';
import logo from '../../assets/projects/logo1.svg';
import f2b from '../../assets/projects/F2B.png';
import shopfloor from '../../assets/projects/shopfloor.png';
import auth from '../../assets/projects/authy.png';
import exptitle from '../../assets/projects/expetitle.svg'
import ScrollAnimation from "react-animate-on-scroll";


export function Project() {
  return (
    <Container id="project">
      <h2>My Projects</h2>
      <div className="projects">
      <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <img src={sof} width="100" alt="sog"></img>
              <div className="project-links">
              </div>
            </header>
            <div className="body">
              <h3>15 Seconds of Fame</h3>
              <p style={{ lineHeight: '1.5em', height: '3em', overflow: 'hidden' }}>
              15 Seconds of Fame is a simple way for fans to receive their video appearances from live events.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>React</li>
                <li>Playwright</li>
                <li>Appium</li>
                <li>Jenkins</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <img src={logo} alt="logo" width="200"></img>
            </header>
            <div className="body">
              <h3>Monk</h3>
              <p style={{ lineHeight: '1.5em', height: '3em', overflow: 'hidden' }}>
              Monk, an expert autonomous AI DevOps agent trained on live application architectures. Being aware of the lowest-level infrastructure to the highest application intent, Monk selects, builds, and runs a deployable diagram. Using our GUI Whiteboard or code, the running deployable designs are instrumented, inspectable and editable. Monk communicates through a chat interface and is system-aware of both the existing configuration and all potential options.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>Vue</li>
                <li>Cypress</li>
                <li>GitHub Actions</li>
                <li>Typescript</li>
                <li>Jira</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header style={{marginTop: '20px'}}>
                <img src={f2b} width="400" alt="f2b"></img>
            </header>
            <div className="body">
              <h3>Footsteps2Brilliance</h3>
              <p>
              Footsteps2Brilliance® is a transformative pre-K through 3rd grade literacy solution that utilizes mobile technology to connect school, home, and the community for academic success. 
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>React</li>
                <li>Cypress</li>
                <li>Typescript</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <img src={shopfloor}  width="300" alt="test"/>
            </header>
            <div className="body">
              <h3>Shopfloor.io</h3>
              <p>
              shopfloor.io is an Industrial IoT application for cloud or on-premises operation with which you can connect your systems, products and employees and visualize all relevant data in well-structured cockpits. Tapping into the IoT potential of your machinery creates added value for the manufacturer, the operator and the end-users of the equipment.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>Angular</li>
                <li>Typescript</li>
                <li>Gitlab Pipelines</li>
                <li>Docker</li>
                <li>Nest.js</li>
                <li>Cypress</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
            <img src={auth} alt="auth" width="400"></img>
            </header>
            <div className="body">
              <h3>Authenticity Leads</h3>
              <p>
              A boutique tool to maximize profit, increase customer satisfaction, and gain an edge on your competition
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>Vue</li>
                <li>Javascript</li>
                <li>CSS</li>
                <li>Playwright</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header style={{marginTop: '20px'}}>
              <img src={exptitle} alt="expetitle" width="250"></img>
            </header>
            <div className="body">
              <h3>Expetitle</h3>
              <p>
              Expetitle is a multi-state title company that uses technology to provide a better closing experience for realtors and their customers.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>React</li>  
                <li>Express.js</li>  
                <li>Playwright</li>  
                <li>TeamCity</li>              
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
       

      </div>
    </Container>
  );
}